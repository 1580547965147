<template>
  <v-container class="mt-4 bl_term">
    <p class="pt-4 mb-0 text-h6">
      使用許諾条件
    </p>

    <v-divider></v-divider>

    <v-row class="mt-4 px-4 text-body-2">
      <p>下記の使用許諾条件を確認し同意の上、「使用許諾条件に同意する」ボタンをクリックしてください。</p>
      <ol class="text-text ">
        <li>本プログラムの利用者（以下単に「利用者」といいます。）は、本ブログラムを無料で使用することができます。ただし、その際の通信料等は利用者が負担するものとします。</li>
        <li>プログラム開発元等は、理由の如何を問わず、利用者に事前の通知等をすることなく、本プログラムの全部又は一部を変更、追加、削除等（本プログラムの提供の一時的な停止を含みます。以下同じ。）することができるものとします。</li>
        <li>プログラム開発元等は、本プログラムについて、その完全性、正確性、確実性その他いかなる事項に関する保証も行わないものとします。</li>
        <li>プログラム開発元等は、利用者が本プログラムを使用したことに伴っていかなる損害、損失等が生じたとしても、これらについて一切の保証責任及び賠償責任を負わないものとします。</li>
        <li>プログラム開発元等は、第 2 項により本プログラムの全部又は一部が変更、追加、削除等されたことに伴っていかなる損害、損失等が生じたとしても、これらについて一切の保証責任及び賠償責任を負わないものとします。</li>
        <li>プログラム開発元等は、本プログラムを本サービスで明記する注意制限事項等で特定する以外の機器又はプログラムと組み合わせること、また、第 8 項⑧に反して本プログラムを改変すること等に起因して生じた損害、損失等について一切の責任を負わないものとします。</li>
        <li>本プログラムを使用する際にアップロードされる建築設備の仕様等や計算結果及び評価結果に関する情報は、次に掲げる目的で、管理・利用されます。
          <ul style="list-style-type:disc;">
            <li>国土交通省住宅局及び所管行政庁等における建築物の省エネ性能の向上に関する施策の検討</li>
          </ul>
        </li>
        <li>利用者は、本システムの利用にあたり、次に掲げる行為をしてはなりません。
          <ol class="hp_ol__circle">
            <li>法令、裁判所の判決、決定若しくは命令、若しくは法令上拘束力のある行政措置に違反する行為又はこれらを助長する行為。</li>
            <li>犯罪行為に関連する行為。</li>
            <li>国土交通省又はプログラム開発元等や第三者の知的財産権を侵害する行為。</li>
            <li>国土交通省又はプログラム開発元等や第三者の肖像権、プライバシー、名誉、その他の権利又は利益を侵害する行為。</li>
            <li>国土交通省、プログラム開発元等又は第三者のサーバー又はネットワークに過度の負担をかけたり、その正常な作動を妨害する行為。</li>
            <li>国土交通省又はプログラム開発元等のサービスの運営を妨害するおそれのある行為。</li>
            <li>不正アクセスをし、又はこれを試みる行為。</li>
            <li>逆アセンブル、逆コンパイル、リバースエンジニアリング等によって本システムのソースコードを解析する行為。</li>
            <li>本システムに権限なく不正にアクセスし又は国土交通省若しくはプログラム開発元等設備に蓄積された情報を不正に書き換え若しくは消去する行為。</li>
            <li>本システムのウェブサイトやソフトウェアを複製、送信、譲渡、貸与又は改変する行為。</li>
            <li>本システム上のコンテンツ等を第三者に有償で貸与、譲渡、売買等をする行為。</li>
            <li>本システムによって得られた情報を商業的に利用する行為。</li>
            <li>本システム上に故意に虚偽の事実を登録し、又はアップロードする行為。</li>
            <li>国土交通省又はプログラム開発元等が意図しない方法によって本システムに関連して利益を得ることを目的とする行為。</li>
            <li>国土交通省又はプログラム開発元等が許諾しない本システム上での宣伝、広告、勧誘、又は営業行為。</li>
            <li>他の利用者に関する個人情報等を収集又は蓄積する行為。</li>
            <li>違法、不正又は不当な目的を持って本システムを利用する行為。</li>
            <li>本システムの他の利用者又はその他の第三者に不利益、損害、不快感を与える行為。</li>
            <li>他の利用者に成りすます行為。</li>
            <li>反社会的勢力等（暴力団、暴力団員、暴力団員でなくなった時から 5 年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋、社会運動等標ぼうゴロ又は特殊知能暴力集団等その他これらに準じる者をいう。以下同じ。）に対して直接又は間接に利益を供与する行為。</li>
            <li>公序良俗に違反する行為。</li>
            <li>その他、国土交通省又はプログラム開発元等が不適切と判断する行為。</li>
          </ol>
        </li>
      </ol>
    </v-row>

    <v-row
    justify="center"
    class="bl_fixedFooter"
    >
      <v-btn
        @click="acceptTerm"
        width="350"
        max-width="80vw"
        height="60"
        class="white--text primary font-weight-bold text-h6">
          使用許諾条件に同意する
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  mounted() {
    // ページ位置を一番上にする
    window.scrollTo(0,0)

    this.updateIsTermAccepted(false)
  },

  computed:{
    ...mapGetters(['isTermAccepted'])
  },

  methods: {
    ...mapActions(['updateIsTermAccepted']),

    // 使用許諾条件に同意した処理
    acceptTerm() {
      this.updateIsTermAccepted(true)
      this.$router.push('/household-type')
    }
  }
}
</script>

<style scoped>
@media (max-width: 959px){
  .bl_term .bl_fixedFooter {
    max-width: clamp(100vw, 959px, 100%) !important;
    left: 0 !important;
  }

}
</style>